import React from 'react';
import styled from 'styled-components';
import tiger from '../../assets/images/ranthambore/tiger.jpg';
import nahargahr2 from '../../assets/images/nahargarh/nahargarh2.jpg';
import nahargahr3 from '../../assets/images/nahargarh/nahargarh4.jpg';
import nahargahr6 from '../../assets/images/nahargarh/nahargarh6.jpg';
import nahargahr7 from '../../assets/images/nahargarh/nahargarh7.jpg';
import nahargahr9 from '../../assets/images/nahargarh/nahargarh9.jpg';
import nahargahr10 from '../../assets/images/nahargarh/nahargarh10.jpg';
import nahargahr5 from '../../assets/images/nahargarh/nahargarh5.jpg';
import travelline from '../../assets/images/travel_line_2.svg';
import alsisarstamp from '../../assets/images/nahargarh/alsisar.png';
// home images

const RanthamboreTigers = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    flex-direction: row;
  }
  .left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 5vw 25vw 5vw;
    @media (min-width: 960px) {
      padding: 0 30px 0 30px;
    }
    .left-wrap {
      max-width: 500px;
      position: relative;
      .alsisar-stamp {
        z-index: 0;
        position: absolute;
        left: -200px;
        top: -100px;
        background: url(${alsisarstamp});
        background-size: cover;
        width: 200px;
        height: 200px;
        opacity: 0.6;
        z-index: 0;
      }
    }
    .intro-text {
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    .text {
      margin-bottom: 30px;
      position: relative;
      line-height: 1.2;
    }
    .tg {
      border-collapse: collapse;
      border-spacing: 0;
      vertical-align: middle;
      padding: 10px 15px;
    }
    .tg td {
      padding: 5px;
      border-color: black;
      border-style: solid;
      border-width: 1px;

      font-size: 14px;
      overflow: hidden;
      padding: 10px 5px;
      word-break: normal;
    }
    .tg th {
      padding: 5px;
      border-color: black;
      border-style: solid;
      border-width: 1px;

      font-size: 14px;
      font-weight: normal;
      overflow: hidden;
      padding: 10px 5px;
      word-break: normal;
    }
    .tg .tg-0lax {
      padding: 10px;
      text-align: left;
      vertical-align: middle;
      &.last {
        background: var(--earth);
        color: var(--beige);
        vertical-align: middle;
        text-transform: uppercase;
        padding: 20px;
        a {
          text-decoration: none;
        }
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const FullPage1 = styled.section`
  width: 100vw;
  height: 50vh;
  margin-top: 20px;
  @media (min-width: 768px) {
    height: 100vh;
    margin-top: 200px;
  }
  background-size: cover !important;
  background-position: center center !important;
  background: url(${nahargahr3});

  background-repeat: no-repeat;
`;

const FullPage2 = styled.section`
  width: 100vw;
  height: 50vh;
  margin-top: 20px;
  @media (min-width: 768px) {
    margin-top: 20px;
    height: 100vh;
  }
  background-size: cover !important;
  background-position: center center !important;
  background: url(${nahargahr5});

  background-repeat: no-repeat;
`;
const SplitPage = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  img {
    display: block;
  }
  @media (min-width: 960px) {
    flex-direction: row;
  }
  .left {
    flex: 1;
    margin-top: 20px;
    @media (min-width: 960px) {
      margin-top: 20px;
    }
  }
  .right {
    flex: 1;
    margin-top: 20px;
    @media (min-width: 960px) {
      margin-top: 20px;
    }
  }
`;

export default function RanthamboreSection2() {
  return (
    <>
      <RanthamboreTigers>
        <div className="left">
          <div className="left-wrap">
            <div className="alsisar-stamp" />
            <div className="intro-text b">Nahargarh, Ranthambore</div>
            <div className="text">
              <p>
                Nahargarh is magnificently poised near the forest of
                Ranthambhore, amidst the dense expanse of trees and abundant
                wildlife. This fascinating royal retreat is just a few yards
                away from Ranthambhore National Park- the Tiger inhabited
                wildlife sanctuary of Rajasthan.
              </p>
              <p>
                Stretched across acres of land, and encompassed by lush
                wilderness, Nahargarh dressed in ivory white fortification and
                set amidst thousands of trees offers a tranquil experience of
                royalty and wilderness.
              </p>
            </div>
          </div>
        </div>
        <div className="right">
          <img src={nahargahr6} alt="" />
        </div>
      </RanthamboreTigers>
      <FullPage1 />
      <SplitPage>
        <div className="left">
          <img src={nahargahr9} alt="" />
        </div>
        <div className="right">
          <img src={nahargahr10} alt="" />
        </div>
      </SplitPage>
      <FullPage2 />
    </>
  );
}
