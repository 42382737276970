import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import styled from 'styled-components';
import Travelline from '../travellines/Travelline1';
import travelline2 from '../../assets/images/travel_line_2.svg';
// import heroImage from '../../assets/images/nahargarh/nahargarh5.jpg';
import heroImage from '../../assets/images/nahargarh/nahargarh7.jpg';
import tigerbg from '../../assets/images/ranthambore/tiger.svg';

// home images
const Hero = styled.div`
  padding: 7.5vw 0;
  grid-column: 1 / -1;
  img {
    width: 100%;
  }
`;
const HomeHeroStyles = styled.div`
  padding: 7.5vw;
  grid-column: 1 / -1;
  .HeroImagewrapper {
    overflow: hidden;

    // display: flex;
    // align-items: center;
    .inner {
      opacity: 0;
      height: calc(100vh - 27vw);
      @media (min-width: 768px) {
        height: calc(100vh - 14vw);
      }
    }
    img {
      max-width: unset;
      width: 110%;
      height: 110%;
      object-fit: cover;
      object-position: 100% 50%;
      // width: 100%;
      @media (min-width: 768px) {
        max-width: unset;
        width: 110%;
        height: 110%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
`;

const RanthamboreIntrowrap = styled.div`
  background: url(${travelline2});
  background-size: 100%;
  background-repeat: no-repeat;
`;
const RanthamboreIntro = styled.div`
  grid-column: 1 / -1;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .homeIntroWrapper {
    text-align: center;
    padding-bottom: 100px;
    max-width: 900px;
    font-size: 2vw;
    img {
      width: 100%;
    }
    .bigText {
      font-family: 'magnetic fields', sans-serif;
      font-size: 6.2vw;
      position: relative;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      color: var(--olive);
      text-transform: uppercase;
      .tiger {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 500px;
        width: 500px;
        transform: translate(-50%, -50%);
        z-index: -1;
        width: 100%;
        opacity: 0.3;
      }
    }
  }
`;

const transition = {
  ease: [0.33, 1, 0.68, 1],
  duration: 1,
  delay: 0.1,
};

export default function RanthamboreHero() {
  const { scrollYProgress } = useViewportScroll();

  const ref = useRef();

  // Stores the start and end scrolling position for our container
  const [scrollPercentageStart, setScrollPercentageStart] = useState(null);
  const [scrollPercentageEnd, setScrollPercentageEnd] = useState(null);

  // const { scrollY, scrollYProgress } = useViewportScroll();
  // Use the container's start/end position percentage

  // const yRange1 = useTransform(scrollYProgress, [0, 1], [200, -200]);

  const yRange = useTransform(scrollYProgress, [0, 1], [-40, 600]);
  const pathLength = yRange;

  // const { scrollYProgress } = useViewportScroll();
  // const scrollY = scrollYProgress.scrollYProgress;
  useLayoutEffect(() => {
    // Get the distance from the start of the page to the element start
    const rect = ref.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetStart = rect.top + scrollTop;
    const offsetEnd = scrollTop + rect.height;
    const elementScrollStart = offsetStart / document.body.clientHeight;
    const elementScrollEnd = offsetEnd / document.body.clientHeight;
    setScrollPercentageStart(elementScrollStart);
    setScrollPercentageEnd(elementScrollEnd);
  });
  return (
    <div>
      <Travelline />
      <HomeHeroStyles className="">
        <div className="HeroImagewrapper">
          <motion.div
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={transition}
            className="inner"
          >
            <motion.img
              src={heroImage}
              alt=""
              ref={ref}
              style={{ y: yRange }}
              transition={{ type: 'spring', stiffness: 100 }}
            />
          </motion.div>
        </div>
      </HomeHeroStyles>
    </div>
  );
}
