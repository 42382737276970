import React from 'react';
import styled from 'styled-components';
import Nahargarh from '../components/nahargarh/nahargarhhero';
import Nahargarh2 from '../components/nahargarh/nahargarhSection2';

// home images

export default function Venue() {
  return (
    <>
      <Nahargarh />
      <Nahargarh2 />
    </>
  );
}
